import {
	Accessor,
	Component,
	createEffect,
	createMemo,
	createSignal,
	For,
	MergeProps,
	mergeProps,
	on,
	onCleanup,
	onMount,
	Setter,
	Show,
	untrack,
} from 'solid-js'
import { debounce, Scheduled } from '@solid-primitives/scheduled'
import IconCaretDown from '@/assets/icons/career/BWEGT_CAREER_CARET_DOWN.svg'
import IconClear from '@/assets/icons/career/BWEGT_CAREER_CLEAR.svg'
import { CareerLocationsQuery } from '@/shared/generated/graphql'

const Multiselect: Component<DropdownProps> = props => {
	let ref!: HTMLDivElement
	let searchInputRef!: HTMLInputElement
	const [features, setFeatures]: [Accessor<any>, Setter<any>] = createSignal([])

	const mergedProps: MergeProps<[DropdownPropsInternal, DropdownProps]> = mergeProps(defaultProps, props)

	const [isOptionAvailable, setIsOptionAvailable]: [Accessor<boolean>, Setter<boolean>] = createSignal(false)

	// Merge props won't merge recursively, we only need default props, so ignore reactivity
	// eslint-disable-next-line solid/reactivity
	mergedProps.styleClasses = mergeProps(defaultStyleClasses, props.styleClasses)
	// eslint-disable-next-line solid/reactivity
	mergedProps.settings = mergeProps(defaultSettings, props.settings)
	// eslint-disable-next-line solid/reactivity
	mergedProps.aria = mergeProps(defaultAriaLabels, props.aria)

	const initOptions = (mergedData: DropdownData[]): void => {
		// set selected values
		// untrack to prevent infinite loop because mergedData would trigger effect again
		untrack(() => {
			if (mergedProps.features.length > 0) {
				mergedData = featuresPresent(mergedData)
			} else {
				mergedData = featuresNotPresent(mergedData)
			}
			setFeatures(mergedProps.features)
			setOptions(mergedData)
			setData(mergedData.filter(x => x.selected))
			if (mergedProps.settings.sort) {
				setOptions(options().sort((a, b) => (a.name > b.name ? 1 : -1)))
			}
			// set all data objects to selected true
			options().map((x: DropdownData) => {
				if (x.selected && !data().some(y => y.value === x.value)) {
					setData([...data(), x])
				}
			})
			setOptions(moveGroupObjectsToEnd(options()))
		})
	}

	const moveGroupObjectsToEnd = (dropdownData: DropdownData[]): DropdownData[] => {
		// Separate objects with and without the 'group' property
		const noGroup: DropdownData[] = []
		const withGroup: DropdownData[] = []
		for (const item of dropdownData) {
			if ('group' in item && item['group']) {
				withGroup.push(item)
			} else {
				noGroup.push(item)
			}
		}

		// Sort objects with the 'group' property by group name
		withGroup.sort((a, b) => (a['group']! < b['group']! ? -1 : 1))

		// Combine the arrays with sorted objects with group first, then without group
		return mergedProps.settings.groupsPosition === 'bottom' ? [...noGroup, ...withGroup] : [...withGroup, ...noGroup]
	}

	const featuresPresent = (mergedData: DropdownData[]): DropdownData[] => {
		mergedData = mergedData.map((item: DropdownData) => ({
			...item,
			feature: mergedProps.features.some((feature: Location) => feature.name === item.name),
		}))
		if (JSON.stringify(features()) !== JSON.stringify(props.features)) {
			if (features().length > 0) {
				mergedData.forEach((item: DropdownData) => {
					if (features().some((feature: Location) => feature.name === item.name)) {
						item.selected = false
						item.feature = false
					}
				})
			}
			return mergedData.map((item: DropdownData) => ({
				...item,
				selected: mergedProps.features.some(
					(feature: Location) =>
						item.selected ||
						item.feature ||
						mergedProps.features.some((_feature: Location) => _feature.name === item.name)
				),
				feature: mergedProps.features.some((_feature: Location) => _feature.name === item.name),
			}))
		} else {
			return mergedData.map((item: DropdownData) => ({
				...item,
				selected: item.selected,
			}))
		}
	}
	const featuresNotPresent = (mergedData: DropdownData[]): DropdownData[] => {
		return mergedData.map((item: DropdownData) => ({
			...item,
			feature: false,
			selected: features().length > 0 ? false : item.selected,
		}))
	}

	// internal/ settings
	const [visibility, setVisibility]: [Accessor<boolean>, Setter<boolean>] = createSignal(false)
	const [search, setSearch]: [Accessor<string>, Setter<string>] = createSignal('')
	createEffect(
		// serach by input
		on(search, () => {
			setOptions(mergedProps.data.filter(item => item.name.toLowerCase().includes(search().toLowerCase())))
		})
	)
	// options is filtered options / data is return values
	const [options, setOptions]: [Accessor<DropdownData[]>, Setter<DropdownData[]>] = createSignal<DropdownData[]>([])
	const [dataSignal, setData]: [Accessor<DropdownData[]>, Setter<DropdownData[]>] = createSignal<DropdownData[]>([])
	const data = createMemo(() => dataSignal(), [], { equals: (a, b) => JSON.stringify(a) === JSON.stringify(b) })

	// handle data changes (debounce to prevent multiple calls on checkbox click)
	// eslint-disable-next-line
	const changeData: Scheduled<[value: DropdownData | null]> = debounce((item: DropdownData | null): void => {
		if (!item) {
			setData([])
			props?.selectedItems?.(data())
			return
		}
		if (mergedProps.settings.multiSelect) {
			if (
				data()
					.map((x: DropdownData) => x.value)
					.includes(item.value)
			) {
				setData(data().filter((x: DropdownData) => x.value !== item.value))
			} else {
				setData([...data(), item].sort((a, b) => (a.name > b.name ? 1 : -1)))
			}
		}
		if (!mergedProps.settings.multiSelect) {
			setData(data()[0] === item && mergedProps.settings.allowDeSelect ? [] : [item])
			if (mergedProps.settings.closeOnSingleSelect) {
				setVisibility(false)
			}
		}
		data().map((x: DropdownData) => ({ ...x, selected: true }))
		props?.selectedItems?.(data())
	})

	const dataContainsObject = (value: DropdownData): boolean => {
		return data().some((item: DropdownData) => item.value === value.value)
	}

	// close dropdown on click / keypress outside / keyboard navigation and selection
	const handleEvent = (event: Event): void => {
		if (!ref.contains(event.target as Node)) {
			setVisibility(false)
			return
		}
		if (event instanceof KeyboardEvent && event.key === 'ArrowDown') {
			arrowDown(event)
			return
		}
		if (event instanceof KeyboardEvent && event.key === 'ArrowUp') {
			arrowUp(event)
			return
		}
		if (
			(event instanceof KeyboardEvent && event.key === 'Enter') ||
			(event instanceof KeyboardEvent && event.key === 'Space') ||
			(event instanceof KeyboardEvent && event.key === ' ')
		) {
			action(event)
		}
	}
	const arrowUp = (event: Event): void => {
		const currentElemIndex = document?.activeElement?.id.match(/\d+$/)
		if (!currentElemIndex) {
			// if previous element is disabled, search for the previous enabled element
			event.preventDefault()
			return
		}
		if (
			document.getElementById('lsb_elem_checkbox_' + mergedProps.label.button + '_' + (Number(currentElemIndex[0]) - 1))
				?.ariaDisabled === 'false'
		) {
			document
				.getElementById('lsb_elem_checkbox_' + mergedProps.label.button + '_' + (Number(currentElemIndex[0]) - 1))
				?.focus()
			return
		}
		for (let i = Number(currentElemIndex[0]) - 1; i > 0; i--) {
			if (document.getElementById('lsb_elem_checkbox_' + mergedProps.label.button + '_' + i)?.ariaDisabled === 'false') {
				document.getElementById('lsb_elem_checkbox_' + mergedProps.label.button + '_' + i)?.focus()
				break
			}
		}
	}
	const arrowDown = (event: Event): void => {
		const currentElemIndex = document?.activeElement?.id.match(/\d+$/)
		if (!currentElemIndex) {
			event.preventDefault()
			return
			// if next element is disabled, search for the next enabled element
		}
		if (
			document.getElementById('lsb_elem_checkbox_' + mergedProps.label.button + '_' + (Number(currentElemIndex[0]) + 1))
				?.ariaDisabled === 'false'
		) {
			document
				.getElementById('lsb_elem_checkbox_' + mergedProps.label.button + '_' + (Number(currentElemIndex[0]) + 1))
				?.focus()
			event.preventDefault()
			return
		}
		for (let i = Number(currentElemIndex[0]) + 1; i < options().length + 2; i++) {
			if (document.getElementById('lsb_elem_checkbox_' + mergedProps.label.button + '_' + i)?.ariaDisabled === 'false') {
				document.getElementById('lsb_elem_checkbox_' + mergedProps.label.button + '_' + i)?.focus()
				break
			}
		}
		event.preventDefault()
	}
	const action = (event: Event): void => {
		const currentElemIndex = document?.activeElement?.id.match(/\d+$/)
		if (!currentElemIndex) {
			return
		}
		document.getElementById('lsb_elem_checkbox_' + mergedProps.label.button + '_' + Number(currentElemIndex[0]))?.click()
		if (!mergedProps.settings.searchField || (mergedProps.settings.searchField && Number(currentElemIndex[0]) !== 1)) {
			event.preventDefault()
		}
	}

	const onClick = () => {
		setVisibility(!visibility())

		if (visibility() && (props.settings?.searchField ?? false) && props.features?.length === 0) {
			searchInputRef.focus()
		}
	}

	onMount((): void => {
		document.addEventListener('click', handleEvent)
		document.addEventListener('keydown', handleEvent)
	})
	onCleanup((): void => {
		document.removeEventListener('click', handleEvent)
		document.addEventListener('keydown', handleEvent)
	})

	createEffect(() => {
		// if any option is not disabled, set isOptionAvailable to true
		setIsOptionAvailable(options().some(x => !x.disabled))
	})

	// react to data changes
	createEffect((): void => {
		initOptions(mergedProps.data)
	})

	createEffect(() => {
		mergedProps.features
		initOptions(mergedProps.data)
	})

	return (
		<div ref={ref} class={mergedProps.styleClasses.dropdownContainer}>
			<button
				type="button"
				onClick={onClick}
				aria-label={mergedProps.aria.mainButton}
				class={
					visibility()
						? mergedProps.styleClasses.dropdownButton + ' ' + mergedProps.styleClasses.dropdownButtonActive
						: mergedProps.styleClasses.dropdownButton
				}
				id={'lsb_elem_checkbox_' + mergedProps.label.button + '_' + 0}>
				<span
					class={mergedProps.styleClasses.dropdownButtonLabel}
					classList={{ 'max-w-[calc(100%-55px)]': props.settings?.showClear && data().length > 0 }}>
					{mergedProps.settings.showSelected && data().length > 0
						? data()
								.map(value => value.name)
								.join(', ')
						: mergedProps.label.button}
				</span>
				<Show when={props.settings?.showClear && data().length > 0}>
					<IconClear
						onClick={e => {
							e.stopPropagation()
							changeData(null)
						}}
						class="absolute right-[45px]"
						width="12.262"
						height="12.262"
					/>
				</Show>
				<IconCaretDown
					aria-hidden="true"
					class={mergedProps.styleClasses.dropdownButtonCaret}
					style={{ transform: visibility() ? 'rotate(180deg)' : 'rotate(0deg)' }}
				/>
			</button>
			<div
				class={mergedProps.styleClasses.dropdownListContainer}
				style={{ visibility: visibility() ? 'visible' : 'hidden', position: 'absolute' }}>
				<div class={mergedProps.styleClasses.dropdownListScrollContainer}>
					<Show
						when={
							!isOptionAvailable() && props.settings?.noOptionsLabel && props.settings?.noOptionsLabel?.length > 0
						}>
						<p class={mergedProps.styleClasses.dropdownListNoOptionLabel}>{props.settings?.noOptionsLabel}</p>
					</Show>
					<Show when={mergedProps.features.length > 0}>
						<Show when={mergedProps.label.feature}>
							<label for="featureBox">{mergedProps.label.feature}</label>
						</Show>
						<ul id="featureBox" role="listbox" aria-multiselectable="true">
							<For each={options()}>
								{(item: DropdownData, index: Accessor<number>) => {
									return (
										<Show when={item.feature}>
											<li
												class={
													(dataContainsObject(item)
														? mergedProps.styleClasses.dropdownListItem +
															' ' +
															mergedProps.styleClasses.dropdownListItemSelected
														: mergedProps.styleClasses.dropdownListItem) +
													(item.disabled ? ' disabled' : '')
												}
												onClick={() => (item.disabled ? null : changeData(item))}
												id={'lsb_elem_list_' + mergedProps.label.button + '_' + item.value}>
												<label
													class={mergedProps.styleClasses.dropdownListLabelContainer}
													classList={{ disabled: item.disabled }}>
													<input
														type="checkbox"
														checked={dataContainsObject(item)}
														class={mergedProps.styleClasses.dropdownListItemCheckbox}
														onInput={() => changeData(item)}
														aria-selected={dataContainsObject(item)}
														aria-label={item.value}
														aria-disabled={item.disabled}
														role={item.disabled ? 'presentation' : 'option'}
														id={
															'lsb_elem_checkbox_' +
															mergedProps.label.button +
															'_' +
															(index() + (mergedProps.settings.searchField ? 2 : 1)).toString()
														}
														disabled={item.disabled}
													/>
													{item.name}
													<span class={mergedProps.styleClasses.dropdownListItemLabel} />
												</label>
											</li>
										</Show>
									)
								}}
							</For>
						</ul>
						<Show when={mergedProps.label.featureFollowUp}>
							<label for="optionBox">{mergedProps.label.featureFollowUp}</label>
						</Show>
					</Show>
					<Show when={mergedProps.settings.searchField}>
						<input
							ref={searchInputRef}
							placeholder={mergedProps.label.search}
							type="text"
							value={search()}
							onInput={event => {
								setSearch(event.currentTarget.value)
							}}
							class={mergedProps.styleClasses.dropdownListSearch}
							aria-label={mergedProps.aria.searchField}
							id={'lsb_elem_checkbox_' + mergedProps.label.button + '_' + 1}
							aria-disabled={false}
						/>
					</Show>
					<Show when={mergedProps.settings.multiSelect}>
						<ul id="optionBox" role="listbox" aria-multiselectable="true">
							<For
								each={options()}
								fallback={
									<div class={mergedProps.styleClasses.dropdownListItem}>{mergedProps.label.noSelection}</div>
								}>
								{(item: DropdownData, index: Accessor<number>) => {
									return (
										<>
											<Show when={item.group && options()[index() - 1]?.group !== item.group}>
												<label class={mergedProps.styleClasses.dropdownListItemGroupLabels}>
													{item.group}
												</label>
											</Show>
											<Show when={!item.feature && !(mergedProps.features.length > 0 && item.disabled)}>
												<li
													class={
														(dataContainsObject(item)
															? mergedProps.styleClasses.dropdownListItem +
																' ' +
																mergedProps.styleClasses.dropdownListItemSelected
															: mergedProps.styleClasses.dropdownListItem) +
														(item.disabled ? ' disabled' : '')
													}
													onClick={() => (item.disabled ? null : changeData(item))}
													id={'lsb_elem_list_' + mergedProps.label.button + '_' + item.value}>
													<label
														class={mergedProps.styleClasses.dropdownListLabelContainer}
														classList={{ disabled: item.disabled }}>
														<input
															type="checkbox"
															checked={dataContainsObject(item)}
															class={mergedProps.styleClasses.dropdownListItemCheckbox}
															onInput={() => changeData(item)}
															aria-selected={dataContainsObject(item)}
															aria-label={item.value}
															aria-disabled={item.disabled}
															role={item.disabled ? 'presentation' : 'option'}
															id={
																'lsb_elem_checkbox_' +
																mergedProps.label.button +
																'_' +
																(index() + (mergedProps.settings.searchField ? 2 : 1)).toString()
															}
															disabled={item.disabled}
														/>
														{item.name}
														<span class={mergedProps.styleClasses.dropdownListItemLabel} />
													</label>
												</li>
											</Show>
										</>
									)
								}}
							</For>
						</ul>
					</Show>
					<Show when={!mergedProps.settings.multiSelect}>
						<ul role="listbox" tabindex={0}>
							<For
								each={options()}
								fallback={
									<div class={mergedProps.styleClasses.dropdownListItem}>{mergedProps.label.noSelection}</div>
								}>
								{(item: DropdownData, index: Accessor<number>) => {
									return (
										<>
											<Show when={item.group && options()[index() - 1]?.group !== item.group}>
												<label class={mergedProps.styleClasses.dropdownListItemGroupLabels}>
													{item.group}
												</label>
											</Show>
											<li
												onClick={() => (item.disabled ? null : changeData(item))}
												class={mergedProps.styleClasses.dropdownListItemCheckbox}
												classList={{ disabled: item.disabled }}
												role={item.disabled ? 'presentation' : 'option'}
												aria-selected={dataContainsObject(item)}
												aria-label={item.name}
												aria-disabled={item.disabled}
												id={
													'lsb_elem_checkbox_' +
													mergedProps.label.button +
													'_' +
													(index() + (mergedProps.settings.searchField ? 2 : 1)).toString()
												}
												tabindex={index() + (mergedProps.settings.searchField ? 2 : 1)}>
												<span
													class={mergedProps.styleClasses.dropdownListItemLabel}
													classList={{ selected: item.selected }}>
													{item.name}
												</span>
											</li>
										</>
									)
								}}
							</For>
						</ul>
					</Show>
				</div>
			</div>
		</div>
	)
}

type Location = CareerLocationsQuery['findLocations'][number]

interface DropdownProps {
	data: DropdownData[]
	features?: Location[]
	label?: DropdownLables
	aria?: DropdownAriaLabels
	settings?: DropdownSettings
	styleClasses?: DropdownStyleClasses
	selectedItems?: (items: DropdownData[]) => void
}

// duplicate beacause: props have to be optional, internal props are required but are provided by mergeProps
interface DropdownPropsInternal {
	data: DropdownData[]
	features: Location[]
	label: DropdownLablesInternal
	aria: DropdownAriaLabelsInternal
	settings: DropdownSettingsInternal
	styleClasses: DropdownStyleClassesInternal
	selectedItems?: (items: DropdownData[]) => void
}

/**
 * Interface for DropdownData
 *
 * @interface
 *
 * @property {string} name - The name or label of the option
 * @property {string} value - Value of the option
 * @property {boolean} disabled - If the option is disabled
 * @property {boolean} selected - If the option is selected
 * @property {boolean} feature - If the option is a feature (features are put on top seperate from everything else)
 * @property {string} group - Group label for the option (items with the same group label are grouped together)
 * */
export interface DropdownData {
	name: string
	value: string
	disabled: boolean
	selected: boolean
	feature?: boolean
	group?: string
}

/**
 * Interface for DropdownLables
 *
 * @interface
 *
 * @property {string} button - Label for the main button
 * @property {string} search - If searchField is enabled
 * @property {string} noSelection - If searchField is enabled and no options are found
 */
interface DropdownLables {
	button: string
	search: string
	noSelection: string
	feature?: string
	featureFollowUp?: string
}

interface DropdownLablesInternal {
	button: string
	search: string
	noSelection: string
	feature: string
	featureFollowUp: string
}

/**
 * Interface for DropdownAriaLabels
 *
 * @interface
 *
 * @property {string} mainButton - Arialabel for the main button (e.g. "Dropdown Stadt Filter")
 * @property {string} searchField - Arialabel for the search field (e.g. "Suchfeld für schränkt die folgende Auswahl ein")
 */
interface DropdownAriaLabels {
	mainButton?: string
	searchField?: string
}

interface DropdownAriaLabelsInternal {
	mainButton: string
	searchField: string
}

/**
 * Interface for DropdownSettings
 *
 * @interface
 *
 * @property {boolean} closeOnSingleSelect - If the dropdown should close after a single selection (singleSelect only)
 * @property {boolean} multiSelect - If the dropdown should allow multiple selections
 * @property {boolean} searchField - If the dropdown should have a search field
 * @property {boolean} showSelected - If the dropdown should show the selected options in the main button (replace the button label with the selected options)
 * @property {boolean} sort - If the dropdown should sort the options alphabetically
 * @property {boolean} showClear - If the dropdown should show a clear button to clear the selection
 * @property {boolean} allowDeSelect - If the dropdown should allow deselecting the selected option (singleSelect only)
 * @property {'top' | 'bottom'} groupsPosition - If the dropdown should show the group labels on top or bottom
 * @property {string} noOptionsLabel - Label for the no options found message. Only shown if string is not empty
 */
interface DropdownSettings {
	closeOnSingleSelect?: boolean
	multiSelect?: boolean
	searchField?: boolean
	showSelected?: boolean
	sort?: boolean
	showClear?: boolean
	allowDeSelect?: boolean
	groupsPosition?: 'top' | 'bottom'
	noOptionsLabel?: string
}

interface DropdownSettingsInternal {
	closeOnSingleSelect: boolean
	multiSelect: boolean
	searchField: boolean
	showSelected: boolean
	sort: boolean
	showClear: boolean
	allowDeSelect: boolean
	groupsPosition: 'top' | 'bottom'
	noOptionsLabel: string
}

interface DropdownStyleClasses {
	dropdownContainer?: string
	dropdownButton?: string
	dropdownButtonActive?: string
	dropdownButtonLabel?: string
	dropdownButtonCaret?: string
	dropdownListContainer?: string
	dropdownListScrollContainer?: string
	dropdownListNoOptionLabel?: string
	dropdownListSearch?: string
	dropdownListItemGroupLabels?: string
	dropdownListItemCheckbox?: string
	dropdownListItem?: string
	dropdownListItemSelected?: string
	dropdownListItemLabel?: string
	dropdownListLabelContainer?: string
}

interface DropdownStyleClassesInternal {
	dropdownContainer: string
	dropdownButton: string
	dropdownButtonActive: string
	dropdownButtonLabel: string
	dropdownButtonCaret: string
	dropdownListContainer: string
	dropdownListScrollContainer: string
	dropdownListNoOptionLabel: string
	dropdownListSearch: string
	dropdownListItemGroupLabels: string
	dropdownListItemCheckbox: string
	dropdownListItem: string
	dropdownListItemSelected: string
	dropdownListItemLabel: string
	dropdownListLabelContainer: string
}

const defaultLabels: DropdownLablesInternal = {
	button: 'Dropdown',
	search: 'Suche',
	noSelection: 'Keine Optionen gefunden',
	feature: '',
	featureFollowUp: '',
}
const defaultAriaLabels: DropdownAriaLabelsInternal = {
	mainButton: 'Dropdown Auswahl',
	searchField: 'Suchfeld für schränkt die folgende Auswahl ein',
}
const defaultSettings: DropdownSettingsInternal = {
	closeOnSingleSelect: false,
	showSelected: false,
	multiSelect: false,
	searchField: false,
	sort: false,
	showClear: false,
	allowDeSelect: true,
	groupsPosition: 'bottom',
	noOptionsLabel: '',
}
const defaultStyleClasses: DropdownStyleClassesInternal = {
	dropdownContainer: 'dropdown-container',
	dropdownButton: 'dropdown-btn',
	dropdownButtonActive: 'dropbtn-active',
	dropdownButtonLabel: 'dropbtn-label',
	dropdownButtonCaret: 'dropbtn-caret',
	dropdownListContainer: 'dropdown-content',
	dropdownListScrollContainer: 'dropdown-content-scrollcontainer',
	dropdownListNoOptionLabel: 'dropdown-content-no-options',
	dropdownListSearch: 'dropdown-search',
	dropdownListItem: 'dropdown-item',
	dropdownListItemSelected: 'dropdown-item-selected',
	dropdownListItemGroupLabels: 'dropdown-checkbox-group-label',
	dropdownListItemCheckbox: 'dropdown-checkbox',
	dropdownListItemLabel: 'filter-options__checkmark dropdown-item-label',
	dropdownListLabelContainer: 'filter-options__container dropdown-list-label-container',
}
const defaultProps: DropdownPropsInternal = {
	data: [],
	features: [],
	label: defaultLabels,
	aria: defaultAriaLabels,
	settings: defaultSettings,
	styleClasses: defaultStyleClasses,
}

export default Multiselect
